<template>
  <div>
    <a-drawer
      :title="title"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '80px' }"
      @close="addClose"
    >
      <div class="drawer-content">
        基本信息
        <a-divider></a-divider>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          layout="vertical"
        >
          <a-form-model-item prop="name" label="分类名称">
            <a-input
              v-model="form.name"
              placeholder="请输入名称"
              style="width: 50%"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item prop="isShow" label="是否在app上显示">
            <a-switch checked-children="开" un-checked-children="关" v-model="form.isShow"></a-switch>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          关闭
        </a-button>
        <a-button type="primary" @click="submit"> 提交 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { form, rules } from "./form.js";
import {
	newsItemInsert,
	newsItemUpdate,
} from "@/api/operation/news/item";
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		editData: Object,
	},
	data() {
		return {
			title: "新增分类",
			form,
			rules,
		};
	},
	methods: {
		addClose() {
			this.$refs.ruleForm.resetFields();
			this.$emit("addClose");
		},
		success() {
			this.$emit("success");
			this.addClose();
		},
		submit() {
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					if (this.editData.id === null) {
						let res = await newsItemInsert(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					} else {
						let res = await newsItemUpdate(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					}
				}
			});
		},
	},
	watch: {
		editData: {
			handler(obj){
				if(obj.id === null){
					this.title = '新增分类'
				}else{
					this.form = obj
					this.title = '修改分类'
				}
			},
			deep:true,
			immediate: true,
		},
	},
};
</script>

<style></style>
