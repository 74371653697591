export const formItem = [
	{
		type: 'input',
		label:'分类名称',
		prop:'name',
		placeholder:'请输入'
	},
]
export const columns = [
	{
		title: "分类名称",
		dataIndex: "name",
	},
	{
		title: "资讯数",
		dataIndex: "informationNum",
	},
	{
		title: "是否显示在app上",
		dataIndex: "isShow",
		customRender: function (isShow) {
			switch (isShow) {
			case true:
				return '是'
				break;
			case false:
				return '否'
				break;
			default:
				break;
			}
		}
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}