import httpService from "@/request"

// 资讯分类
export function newsItemList(params) {
	return httpService({
		url: `/user/informationCategory/list`,
		method: 'get',
		params: params,
	})
}
export function newsItemUpdate(params) {
	return httpService({
		url: `/user/informationCategory/update`,
		method: 'post',
		data: params,
	})
}
export function newsItemDel(params) {
	return httpService({
		url: `/user/informationCategory/delete`,
		method: 'post',
		data: params,
	})
}
export function newsItemInsert(params) {
	return httpService({
		url: `/user/informationCategory/insert`,
		method: 'post',
		data: params,
	})
}